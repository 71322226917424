<template src="./laboratoryExams.html" />

<script>
import { PhoneIcon } from "vue-feather-icons"
import { computed, defineComponent } from "vue"
import { useStore } from "@/store/store.js"
import mixins from "@/mixins/mixins.js"

export default defineComponent ({
  name: "LaboratoryExams",
  components: {
    PhoneIcon,
  },
  mixins: [mixins],
  data() {
    return {
      cep: "",
      cepInvalid: false,
      queryString: "",
      exams: [],
      examsPaginated: [],
      examsPageCurrent: 1,
      examsTotalPages: 0,
      pageLoadingDone: false,
      selected: null,
      lat: "-21.1934836",
      lng: "-47.8017159",
      labs: [],
      address: "",
      cepLab: "",
      errorStr: "",
      mapLatitude: 0,
      mapLongitude: 0,
      showAlertResultsNotFound: false,
    };
  },

  computed: {
    isSearchDisabled () {
      return !this.cep || this.cep.length < 9
    },
    isPaginated () {
      return this.examsTotalPages > 1
    },
    formattedAddress () {
      if (!this.address) {
        return null
      } else {
        if (typeof this.address === "string") {
          return this.address
        } else {
          const { street: s, neighborhood: n, city: c, state: t } = this.address
          
          return (!s || !n)
            ? `${c} - ${t}`
            : `${s}, ${n} - ${c} - ${t}`
        }
      }
    },
  },

  setup () {
    const store = useStore()

    return {
      isMobile: computed(() => store.getters.isMobile),
      getGoogleAddress: (data) => store.dispatch("getGoogleAddress", data),
      getAddressByZipcode: (cep) => store.dispatch("getAddressByZipcode", cep),
    }
  },

  async mounted () {
    const address = this.$session.get("address")
    this.cep = address.zipcode

    await this.getExams()
    await this.searchAddress()
  },

  methods: {
    getExams() {
      this.$vs.loading()

      const query = [
        `page=${Math.max(this.examsPageCurrent - 1, 0)}`,
        `search=${this.queryString}`,
        "size=12",
        "sort=id,asc",
      ]

      this.axios.get(
        `${this.$store.state.filooServer}exams/exames?${query.join("&")}`,
        { headers: { Authorization: this.$session.get("bearer") } }
      )
        .then(({ status, data }) => {
          if (status === 200) {
            this.exams = data.content
            this.examsTotalPages = data.totalPages
            this.showAlertResultsNotFound = !this.exams.length
            this.pageLoadingDone = true
          }
        })
        .catch((error) => console.warn(error.response.data))
        .finally(() => this.$vs.loading.close())
    },
  
    async handleSelected(lab) {
      this.selected = await lab
      this.cepInvalid = false

      await this.getExamsAddressLab(lab)
      
      this.$bvModal.show("laboratory-exams-popup-discont")
    },
    
    getExamsAddressLab(lab) {
      const url = `${lab.laboratorio.id}/${this.lat}/${this.lng}`

      this.axios.get(
        `${this.$store.state.filooServer}exams/address-laboratorio/${url}`,
        { headers: { Authorization: this.$session.get("bearer") } },
      )
        .then(({ status, data }) => {
          if (status === 200) {
            this.labs = []

            data.forEach((lab) => {
              if (!this.labs.find((obj) => (
                lab.id !== obj.id && JSON.strigify(lab.address) !== JSON.stringify(obj.address)
              ))) {
                this.labs.push(lab)
              }
            })
          }
        })
        .catch((error) => console.warn(error.response.data))
    },

    googleCoords(tr) {
      return {
        lat: parseFloat(tr.latitude),
        lng: parseFloat(tr.longitude),
      };
    },

    googleMarker(id) {
      var vm = this;
      var y = [];
      y.push({ position: vm.googleCoords(id) });
      return y;
    },

    changePagination () {
      if(this.examsPageCurrent > 0){
        this.getExams()
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  
    async searchAddress() {
      this.cepInvalid = false
      this.pharmacies = []

      if (!this.isSearchDisabled) {
        const response = await this.getAddressByZipcode(this.cep)
        
        if (response && response.address) {
          this.lat = response.lat
          this.lng = response.lng
          this.address = response.address

          if (this.selected) {
            this.getExamsAddressLab(this.selected)
          }
        } else {
          this.address = null
          this.cepInvalid = true
  
          this.showNotifyWarning(
            `Não foi possível localizar o endereço através do CEP digitado.
            Digite um CEP válido e tente novamente.`,
            6000
          )
        }
      }
    },

    async getGeolocation() {
      const checkGeolocation = await navigator.permissions.query({ name: "geolocation" })

      if (checkGeolocation.state === "denied") {
        this.errorStr = "Geolocalização não esta habilitada."

        this.showNotifyWarning(
          `Para pesquisar medicamentos é necessário permitir a
          geolocalização. Habilite essa funcionalidade do seu navegador,
          recarregue a página e tente novamente.`,
          9500
        )
      } else {
        navigator.geolocation.getCurrentPosition(
          async ({ coords }) => {
            if (coords.latitude && coords.longitude) {
              const response = await this.getGoogleAddress({
                lat: coords.latitude,
                lng: coords.longitude,
              })

              if (response) {
                const address = response.address_components
                this.address = response.formatted_address
                this.cep = address.find((adr) => adr.types.includes("postal_code")).short_name

                this.getExamsAddressLab(this.selected)
              } else {
                this.showNotifyError("CEP inválido", 6000)
              }
            }
          },
          (err) => {
            this.error = err.message
            this.showNotifyWarning(
              `Geolocalização desabilitada. Para utilizar essa funcionalidade
              habilite essa funcionalidade do seu navegador e tente novamente.`,
              9500
            )
          }
        )
      }
    },

    callMap(latitude, longitude){
      const vm = this;
      vm.mapLatitude = parseFloat(latitude);
      vm.mapLongitude = parseFloat(longitude);
      vm.$bvModal.show("modal-lab-location");
    },
  
    distance(dist) {
      var res = Math.floor(dist);
      res = dist / 1000;
      if (res > 1) {
        return Math.floor(res) + " km";
      } else {
        return res + " m";
      }
    },
  },
  watch: {
    queryString () {
      this.examsPageCurrent = 1
      this.examsTotalPages = 0
      this.exams = []
      this.showAlertResultsNotFound = false
    },
    examsPageCurrent () {
      this.changePagination()
    },
  },
})
</script>

<style lang="scss" src="./laboratoryExams.scss" />